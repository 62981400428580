import Link from 'next/link'
import styled from 'styled-components'

export const DropdownIconContainer = styled.div`
  cursor: pointer;
`

export const ExportHistoryLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  font-size: 1rem;
  line-height: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.architecture3};
  padding: 0.5rem 1rem;
`

export const EmptyState = styled.div`
  width: 394px;
  padding: 2rem 1rem;
  color: ${({ theme }) => theme.colors.typeSecondary};
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  cursor: default;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`
